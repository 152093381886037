import React from "react"
import { navigate } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Radio from "../../../../../components/radio"

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          courseId: "jahreszeiten",
          chapterId: "02-eigenes-gedicht",
          taskId: "thema-waehlen",
          event,
        })
        navigate("/kurse/jahreszeiten/02-eigenes-gedicht/ideen-sammeln")
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Ein Thema auswählen" />
      <Stack>
        <Stack space={3}>
          <Heading as="h1" level={2}>
            Ein Thema auswählen
          </Heading>
        </Stack>
        <Paragraph>
          Überlege dir zunächst, über welche Jahreszeit du schreiben möchtest.
          Vielleicht gibt es eine Jahreszeit, die du ganz besonders magst? Oder
          du lässt dich wie Hölderlin von einem Blick aus dem Fenster
          inspirieren und schreibst über die Jahreszeit, die sich dort gerade
          abzeichnet?
        </Paragraph>
        <Stack space={4}>
          <Radio id="fruehling" name="topic" value="spring">
            Frühling
          </Radio>
          <Radio id="sommer" name="topic" value="summer">
            Sommer
          </Radio>
          <Radio id="herbst" name="topic" value="autumn">
            Herbst
          </Radio>
          <Radio id="winter" name="topic" value="winter">
            Winter
          </Radio>
        </Stack>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
